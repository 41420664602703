var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"do_keep"},[_c('back-step',{attrs:{"currentTxt":_vm.$t('casesDetail.casesChildren.restartCases.cqbl'),"goList":[
      {
        url: '/cases',
        name: _vm.$t('casesDetail.casesChildren.common.bl')
      },
      {
        url: '/cases/detail',
        name: _vm.$t('casesDetail.casesChildren.common.blxq')
      }
    ]}}),_c('div',{staticClass:"plan_head"},[_c('span',{staticClass:"plan_h_strong"},[_vm._v(_vm._s(_vm.$t("casesDetail.casesChildren.restartCases.blh"))+"："+_vm._s(_vm.casesInfo.caseNumber))]),_c('span',{staticClass:"plan_h_sp"},[_vm._v(_vm._s(_vm.casesInfo.clinicName))]),_c('span',{staticClass:"plan_h_sp"},[_vm._v(_vm._s(_vm.casesInfo.productName))])]),_c('div',{staticClass:"cases_d_box"},[(_vm.commonCaseDetail.state !== 'loading')?_c('cases-user-msg'):_vm._e(),_c('p',{staticClass:"restart_tip"},[_vm._v(" "+_vm._s(_vm.$t("casesDetail.casesChildren.restartCases.cqcztip"))+" ")]),_c('div',{staticClass:"cases_d_foo"},[_c('span',{staticClass:"submit_btn flex-x-y-c curp ml36",on:{"click":function($event){_vm.isShow = true}}},[_vm._v(_vm._s(_vm.$t("casesDetail.casesChildren.common.qr")))]),_c('span',{staticClass:"back_btn flex-x-y-c curp",on:{"click":_vm.goBack}},[_vm._v(_vm._s(_vm.$t("casesDetail.casesChildren.common.fh")))])])],1),_c('confirm-modal',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShow),expression:"isShow"}],attrs:{"confirmObj":{
      tipText: _vm.$t('casesDetail.casesChildren.common.qrjx'),
      backText: _vm.$t('casesDetail.casesChildren.common.qx'),
      okText: _vm.$t('casesDetail.casesChildren.common.qd')
    }},on:{"confirmHide":_vm.confirmHide,"confirmSubmit":_vm.confirmSubmit}}),_c('FullLoading',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoading),expression:"isLoading"}]})],1)}
var staticRenderFns = []

export { render, staticRenderFns }